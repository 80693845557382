/* eslint-disable  @typescript-eslint/no-non-null-assertion */
'use client';
import { BuilderComponent, useIsPreviewing } from '@builder.io/react';
import '@builder.io/widgets';
import { BuilderContent, builder } from '@builder.io/sdk';
import DefaultErrorPage from 'next/error';
import '@/builder-registry';
import config from '@/src/config';
import { useEffect, useState } from 'react';
import { SubscriptionService } from '../lib/api/v4/bespoke-client/subcription-service';
import { ramadanChallange10Days } from '../lib/constants/sg-constants';
import { PlanInfo } from '../types';
import { HomeService } from '../lib/api';

interface BuilderPageProps {
  content?: BuilderContent;
}

builder.init(config().buiderIoApiKey);

export function RenderBuilderContent({ content }: BuilderPageProps) {
  const [scheduledGivingTotalSignUps, setScheduledGivingTotalSignUps] =
    useState('0');
  const [newSignUpsToday, setNewSignUpsToday] = useState('0');
  const [totalUsers, setTotalUsers] = useState('');

  //should the planInfo be passed in as a prop here?
  useEffect(() => {
    SubscriptionService.getPlanInfo(ramadanChallange10Days).then(
      (response: PlanInfo) => {
        setScheduledGivingTotalSignUps(response.signedUp.toString());
        setNewSignUpsToday(response.newMembers.toString());
      },
    );
    HomeService.footerStats().then((res) => {
      const { success, message, result } = res;
      if (!success) {
        console.error('Failed to fetch all time stats', message);
        return;
      }
      const { total_users } = result;
      const formatter = new Intl.NumberFormat('en-US');
      const formattedNumber = formatter.format(total_users);
      setTotalUsers(formattedNumber);
    });
  }, []);

  // Call the useIsPreviewing hook to determine if
  // the page is being previewed in Builder
  const isPreviewing = useIsPreviewing();
  // If "content" has a value or the page is being previewed in Builder,
  // render the BuilderComponent with the specified content and model props.
  if (content || isPreviewing) {
    return (
      <BuilderComponent
        apiKey={config().buiderIoApiKey!}
        content={content}
        model="page"
        data={{ scheduledGivingTotalSignUps, newSignUpsToday, totalUsers }}
      />
    );
  }
  // If the "content" is falsy and the page is
  // not being previewed in Builder, render the
  // DefaultErrorPage with a 404.
  return <DefaultErrorPage statusCode={404} />;
}
