import {
  ramadanChallange10Days,
  ramadanChallange30Days,
} from '@/src/lib/constants/sg-constants';
import { OpenAPI as OpenAPIv4 } from '@/src/lib/api';
import { OpenAPI as OpenAPIv3 } from '@/src/lib/api/v3';
import { request as requestv3 } from '@/src/lib/api/v3/client/core/request';
import { request as requestv4 } from '@/src/lib/api/v4/client/core/request';
import {
  CampaignBasics,
  GivingListObject,
  SubscriptionBillingEventResponse,
  SubscriptionObject,
  SubscriptionStatus,
  PlanInfo,
} from '@/src/types';
import {
  GivingListAddDeleteApiResponse30,
  GivingListBrowseApiResponse30,
} from './interfaces';
import {
  canCreateSubscription,
  isSubscriptionActive,
} from '@/src/app/scheduled-giving/sg-utils';

export class SubscriptionService {
  //get subscription from 4.0 with a {planId}, the user's information is acquired from the Bearer token in the 3.0 backend
  public static async getSubscription({
    userId,
    planId,
  }: {
    userId: number;
    planId: number;
  }): Promise<SubscriptionObject> {
    const emptySub: SubscriptionObject = {
      subscriptionStatus: SubscriptionStatus.INACTIVE,
      givingPlanId: 0,
      donationAmount: 0,
      donationAmountCurrency: 'USD',
      donationAmountCurrencySymbol: '$',
      tipAmount: 0,
      tipAmountCurrency: 'USD',
      tipAmountCurrencySymbol: '$',
      cardId: 0,
    };

    try {
      const subscriptions = await this.getSubscriptions({ userId });
      const plan = await this.getPlanInfo(planId);

      const [subscription] = subscriptions.filter(
        ({ givingPlanId }) => givingPlanId === planId,
      );

      //no subscription found
      if (!subscription) {
        //instead of throwing an error (which is reserved for API call failures or auth failures), return an emptySub object that is handled appropriately in the FE
        //subscriptionStatus is INACTIVE, so FE will handle displaying correctly
        return emptySub;
      }

      const status = isSubscriptionActive(subscription, plan)
        ? SubscriptionStatus.ACTIVE
        : SubscriptionStatus.INACTIVE;

      subscription.subscriptionStatus = status;

      return subscription;
    } catch {
      throw new Error('Error in getSubscriptions');
    }
  }

  //delete subscription from 4.0 with a {planId}, the user's information is acquired from the Bearer token in the 3.0 backend
  public static async deleteSubscription(
    subscriptionId: number,
  ): Promise<SubscriptionObject> {
    const res: SubscriptionObject = await requestv4(OpenAPIv4, {
      method: 'DELETE',
      url: `/v4/subscriptions/${subscriptionId}`,
      mediaType: 'application/json',
      errors: {
        400: `Unsuccessful`,
      },
    });

    return {
      ...res,
      subscriptionStatus: SubscriptionStatus.DELETED,
    };
  }

  //TODO: This should be deleted and replaced with SubscriptionService.deleteSubscription(). Every subscription is the same model. No need to create wrappers.
  public static async deleteFutureSubscription({
    id,
  }: {
    id: number;
  }): Promise<SubscriptionObject> {
    const subscriptionObject: SubscriptionObject = {
      subscriptionStatus: '',
      givingPlanId: 0,
      donationAmount: 0,
      donationAmountCurrency: '',
      donationAmountCurrencySymbol: '',
      tipAmount: 0,
      tipAmountCurrency: '',
      tipAmountCurrencySymbol: '',
      cardId: 0,
    };

    requestv4(OpenAPIv4, {
      method: 'POST',
      url: '/v4/subscriptions/unsubscribe',
      body: { givingPlanId: id },
      mediaType: 'application/json',
      errors: {
        400: `Unsuccessful`,
      },
    }).catch((error) => {
      throw new Error('Unable to delete', error);
    });
    return subscriptionObject;
  }

  // Update 4.0 subscription
  public static async updateSubscription(
    id: number,
    payload: SubscriptionObject,
  ): Promise<SubscriptionObject> {
    if (
      payload.cardType &&
      canCreateSubscription(payload.cardType, payload.donationAmountCurrency)
    ) {
      return await requestv4(OpenAPIv4, {
        method: 'PUT',
        url: `/v4/subscriptions/${id}`,
        body: payload,
        mediaType: 'application/json',
        errors: {
          403: `The requested action is not permitted for the current user`,
          404: `The requested entity or resource was not found`,
        },
      });
    } else {
      if (payload.cardType) {
        throw new Error(
          `You cannot use an American Express card for a ${payload.donationAmountCurrency} subscription.`,
        );
      } else {
        throw new Error('Must provide a credit card');
      }
    }
  }

  // Create subscription on 4.0 for any giving plan.
  public static async createSubscription(
    payload: SubscriptionObject,
  ): Promise<SubscriptionObject> {
    const {
      referralSource,
      referralCode,
      donationAmount,
      donationAmountCurrency,
      cardId,
      cardType,
      unsubscribedAt,
      tipAmount,
      tipAmountCurrency,
      additionalDonationAmount,
      additionalTipAmount,
      givingCategories,
      givingPlanId,
      taxReceipt,
      giftAid,
      marketingFlag,
      rewardId,
    } = payload;

    console.debug('< payload in createSubscription', payload);

    if (!givingPlanId) {
      throw new Error('Giving Plan ID is not set correctly.');
    }

    if (cardType && canCreateSubscription(cardType, donationAmountCurrency)) {
      const body = {
        donationAmount,
        donationAmountCurrency,
        cardId,
        cardType,
        source: referralSource
          ? `${referralSource}${referralCode ?? ''}`
          : null,
        unsubscribedAt,
        tipAmount,
        tipAmountCurrency,
        referralCode,
        additionalLastTenDaysRamadanAmount: 0,
        additionalLastTenDaysRamadanTipAmount: 0,
        givingCategories,
        taxReceipt: taxReceipt ? 1 : 0,
        giftAid: giftAid ? 1 : 0,
        marketingFlag: marketingFlag ? 1 : 0,
        rewardId,
      };
      if (
        givingPlanId === ramadanChallange10Days ||
        givingPlanId === ramadanChallange30Days
      ) {
        if (additionalDonationAmount) {
          body.additionalLastTenDaysRamadanAmount =
            additionalDonationAmount as number;
        }
        if (additionalTipAmount) {
          body.additionalLastTenDaysRamadanTipAmount =
            additionalDonationAmount as number;
        }
      }

      console.debug('<< body in createSubscription', body);

      if (referralCode) {
        const givingListId = Array.isArray(givingCategories)
          ? parseInt(givingCategories[0])
          : 0;

        const result = await this.addToGivingList({ campaignId: givingListId });
        const { givingListCampaignIds } = result;
        const index = givingListCampaignIds.indexOf(givingListId.toString());

        if (index > -1) {
          givingListCampaignIds.splice(index, 1);
          givingListCampaignIds.unshift(givingListId.toString());
          givingListCampaignIds.map(Number);
          await this.setGivingListOrder({
            campaignIds: givingListCampaignIds,
          });
        }

        delete body.givingCategories;
        console.debug(
          '<< body after deleting giving categories createSubscription',
          body,
        );
      }

      return requestv4(OpenAPIv4, {
        method: 'POST',
        url: `/v4/giving-plans/${givingPlanId}/subscribe`,
        body,
      });
    } else {
      if (payload.cardType) {
        throw new Error(
          `You cannot use an American Express card for a ${payload.donationAmountCurrency} subscription.`,
        );
      } else {
        throw new Error(
          'Sorry, something went wrong. Please select card again.',
        );
      }
    }
  }

  //get the Giving List for the user from 3.0
  //campaign IDs are returned as string[] in the GivingListObject so that they can be stored in place of Giving Categories in the Subscription Provider
  public static async getGivingList(): Promise<GivingListObject> {
    const apiResponse: GivingListBrowseApiResponse30 = await requestv3(
      OpenAPIv3,
      {
        method: 'POST',
        url: '/api/user/favorite',
        body: { verb: 'project_ids', suggestedListId: '' },
        mediaType: 'application/json',
        errors: {
          403: `The requested action is not permitted for the current user`,
          404: `The requested entity or resource was not found`,
        },
      },
    );

    console.debug('< apiResponse in getGivingList', apiResponse);

    const givingList: GivingListObject = {
      givingListCampaignIds: apiResponse.result.giving_project_ids.map((item) =>
        item.toString(),
      ),
      success: true,
    };

    console.debug('<< givingList in getGivingList', givingList);

    return givingList;
  }

  //add a {campaignID} to the Giving List for the user from 3.0
  //there's no validation of the {campaignID}
  //the API does not return the Giving List, so a call to this.getGivingList() is made here
  public static async addToGivingList({
    campaignId,
  }: {
    campaignId: number;
  }): Promise<GivingListObject> {
    const payload = {
      verb: 'add',
      project_id: campaignId.toString(),
      source: '',
    };

    const apiResponse: GivingListAddDeleteApiResponse30 = await requestv3(
      OpenAPIv3,
      {
        method: 'POST',
        url: '/api/user/favorite',
        body: { ...payload },
        mediaType: 'application/json',
        errors: {
          403: `The requested action is not permitted for the current user`,
          404: `The requested entity or resource was not found`,
        },
      },
    );

    console.debug('< apiResponse in addToGivingList', apiResponse);

    const givingList = await this.getGivingList();

    if (apiResponse.result.favorited) {
      givingList.success = true;
    } else {
      givingList.success = false;
    }

    console.debug('<< givingList in addToGivingList', givingList);

    return givingList;
  }

  //delete a {campaignID} to the Giving List for the user from 3.0
  //there's no validation of the {campaignID}
  //the API does not return the Giving List, so a call to this.getGivingList() is made here
  public static async deleteFromGivingList({
    campaignId,
  }: {
    campaignId: number;
  }): Promise<GivingListObject> {
    const payload = {
      verb: 'remove',
      project_id: campaignId.toString(),
      source: '',
    };

    const apiResponse: GivingListAddDeleteApiResponse30 = await requestv3(
      OpenAPIv3,
      {
        method: 'POST',
        url: '/api/user/favorite',
        body: { ...payload },
        mediaType: 'application/json',
        errors: {
          403: `The requested action is not permitted for the current user`,
          404: `The requested entity or resource was not found`,
        },
      },
    );

    console.debug('< apiResponse in deleteFromGivingList', apiResponse);

    const givingList = await this.getGivingList();

    //this is because the API returns false when the campaign has been successfully removed from the list
    if (!apiResponse.result.favorited) {
      givingList.success = true;
    } else {
      givingList.success = false;
    }

    console.debug('<< givingList in deleteFromGivingList', givingList);

    return givingList;
  }

  public static async setGivingListOrder({
    campaignIds,
  }: {
    campaignIds: string[];
  }): Promise<GivingListObject> {
    const payload = {
      verb: 'set_order',
      ids: campaignIds,
    };

    const apiResponse: GivingListBrowseApiResponse30 = await requestv3(
      OpenAPIv3,
      {
        method: 'POST',
        url: '/api/user/favorite',
        body: { ...payload },
        mediaType: 'application/json',
        errors: {
          403: `The requested action is not permitted for the current user`,
          404: `The requested entity or resource was not found`,
        },
      },
    );

    console.debug('< apiResponse in setOrdering', apiResponse);

    const givingList = await this.getGivingList();

    if (apiResponse.result.giving_project_ids.includes(+campaignIds[0])) {
      givingList.success = true;
    } else {
      givingList.success = false;
    }

    console.debug('<< givingList in addToGivingList', givingList);

    return givingList;
  }

  //returns the basics of a campaign from the 4.0 API
  public static async getCampaignBasics({
    campaignId,
  }: {
    campaignId: number;
  }): Promise<CampaignBasics> {
    const response: CampaignBasics = await requestv4(OpenAPIv4, {
      method: 'GET',
      url: `/v4/campaigns/${campaignId}/basics`,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });

    return response;
  }

  public static async getSubscriptions({
    userId,
  }: {
    userId: number;
  }): Promise<SubscriptionObject[]> {
    try {
      const u = new URLSearchParams();
      u.append('userId', userId.toString());
      return await requestv4(OpenAPIv4, {
        method: 'GET',
        url: `/v4/subscriptions?${u.toString()}`,
        mediaType: 'application/json',
        errors: {
          403: `The requested action is not permitted for the current user`,
          404: `The requested entity or resource was not found`,
        },
      });
    } catch {
      throw new Error('Unable to getSubscriptions');
    }
  }

  public static async getPlanInfo(planId: number): Promise<PlanInfo> {
    const response: PlanInfo = await requestv4(OpenAPIv4, {
      method: 'GET',
      url: `/v4/giving-plans/${planId}?showCalculatedFields=true`,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });

    return response;
  }

  public static async getSubscriptionBillingEvents(
    userId: number,
    {
      take = 10,
      skip = 0,
      orderBy = 'desc',
    }: { take: number; skip: number; orderBy?: 'asc' | 'desc' },
  ): Promise<SubscriptionBillingEventResponse> {
    const u = new URLSearchParams();
    u.append('userId', userId.toString());
    u.append('take', take.toString());
    u.append('skip', skip.toString());
    u.append('orderBy', orderBy);
    const response = await requestv4(OpenAPIv4, {
      method: 'GET',
      url: `/v4/subscriptions/events`,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });

    return response as SubscriptionBillingEventResponse;
  }
}
