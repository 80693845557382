import {
  Currency,
  CurrencyObject,
  ExchangeRateSubjectProps,
} from '@/src/types';
import { BehaviorSubject } from 'rxjs';
import { FxService } from '../services';
import { useContext, useEffect, useState } from 'react';
import { toCurrencyObject } from '../utils';
import moment from 'moment';
import { UserContext } from '../context';

const initialState: ExchangeRateSubjectProps = {
  userSelectedCurrency: null,
  rates: [],
  updatedAt: moment(),
};
let state = initialState;
const subject = new BehaviorSubject<ExchangeRateSubjectProps>(state);

const getSupportedCurrencies = async (): Promise<string[]> => {
  const { result } = await FxService.getSupportedCurrencies();

  return result;
};

const getExchangeRates = async (
  supportedCurrencies: string[],
  currency: Currency,
) => {
  const exchangeRates = await Promise.all(
    supportedCurrencies.map((c) =>
      FxService.exchangeRate(c as Currency, currency),
    ),
  );
  return exchangeRates;
};

const updateState = async (updatedCurrency: Currency) => {
  // get current currency value from subject
  const { userSelectedCurrency: currentCurrency } = subject.getValue();

  // check if the currency is the same. if it is, do nothing
  if (currentCurrency === updatedCurrency) return;

  // get supported currencies incase these change
  const supportedCurrencies = await getSupportedCurrencies();
  // get exchange rates for new currency
  const exchangeRates = await getExchangeRates(
    supportedCurrencies,
    updatedCurrency,
  );

  // update state
  ExchangeRateStore.update({
    userSelectedCurrency: updatedCurrency,
    rates: exchangeRates,
    updatedAt: moment(),
  });
};

export const ExchangeRateStore = {
  init: () => subject.next(state),
  subscribe: (setState: any) => subject.subscribe(setState),
  triggerUpdate: (currency: Currency) => updateState(currency),
  update: (payload: Partial<ExchangeRateSubjectProps>) => {
    state = {
      ...state,
      ...payload,
    };
    subject.next(state);
  },
  unsubscribe: () => subject.unsubscribe(),
  initialState,
};

/**
 *
 * Custom hook to automatically get any monetary amount in the user's selected currency
 *
 * @param amount {number}
 * @param currencyCode {Currency}
 * @param withDecimals {boolean}
 * @returns {CurrencyObject}
 */
export const useExchangeRateHook = (
  amount: number,
  currencyCode: Currency,
  withDecimals: boolean,
): CurrencyObject => {
  const originalCurrency = currencyCode;
  const originalAmount = amount;
  const [currencyCodeState, setCurrencyCode] = useState<Currency>(currencyCode);
  const [currencyObject, setCurrencyObject] = useState<CurrencyObject>(
    toCurrencyObject(amount, currencyCode, withDecimals),
  );
  const [stateAmount, setStateAmount] = useState<number>(amount);
  const [exchangeRate$, setExchangeRate$] =
    useState<ExchangeRateSubjectProps>();
  const { currency: userPreferredCurrency } = useContext(UserContext);

  useEffect(() => {
    const exchangeRateStore$ = ExchangeRateStore.subscribe(setExchangeRate$);
    ExchangeRateStore.init();

    return () => {
      exchangeRateStore$.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (!exchangeRate$) return;
    if (exchangeRate$.rates.length === 0) return;

    console.debug({ userPreferredCurrency, originalCurrency });
    if (userPreferredCurrency && userPreferredCurrency === originalCurrency) {
      setStateAmount(originalAmount);
      setCurrencyCode(originalCurrency);
      return;
    }

    const newRate = exchangeRate$.rates.filter(
      (rate) => rate.from === originalCurrency,
    )[0];
    // guard checks to make sure we have a rate
    // and if the rate is the same as the current rate, just return
    if (!newRate) return;
    if (newRate.from === newRate.to) return;

    const { to: newCurrencyCode, rate } = newRate;

    setStateAmount(originalAmount * rate);
    setCurrencyCode(newCurrencyCode);
  }, [exchangeRate$]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setStateAmount(amount);
  }, [amount]);

  useEffect(() => {
    const newCurrencyObject = toCurrencyObject(
      stateAmount,
      currencyCodeState,
      withDecimals,
    );
    setCurrencyObject({ ...newCurrencyObject });
  }, [stateAmount, currencyCodeState]); // eslint-disable-line react-hooks/exhaustive-deps

  return currencyObject;
};
