'use client';
import { shortenNumber as shortenNumberFn, toCurrencyObject } from '@/src/lib';
import { useExchangeRateHook } from '@/src/lib/store/exchange-rate';
import { CurrencyFormatProps } from '@/src/types';
import { useEffect, useState } from 'react';

export default function CurrencyFormattedNumber({
  amount,
  currencyCode,
  element,
  className,
  withDecimals = false,
  showTrailingDecimals = false,
  shortenNumber = false,
  appendCurrencyCode = false,
  updateWithExchangeRate = true,
  rawText = false,
}: CurrencyFormatProps) {
  const [amountToShow, setAmountToShow] = useState<number | string>(0);
  const Element = element;

  const exchangeRate = useExchangeRateHook(amount, currencyCode, withDecimals);

  const currencyObject = updateWithExchangeRate
    ? exchangeRate
    : toCurrencyObject(amount, currencyCode, withDecimals);

  useEffect(() => {
    if (!currencyObject) return;

    if (shortenNumber) {
      setAmountToShow(
        `${currencyObject.currencySymbol}${shortenNumberFn(
          currencyObject.amount,
        )}`,
      );
      return;
    }

    if (rawText) {
      setAmountToShow(currencyObject.amount);
      return;
    }

    if (showTrailingDecimals) {
      setAmountToShow(
        `${currencyObject.currencySymbol}${currencyObject.amount.toFixed(2)}`,
      );
      return;
    }

    setAmountToShow(currencyObject.amountString);
  }, [currencyObject]);

  return (
    <>
      {currencyObject && (
        <Element className={className}>
          {amountToShow}
          {appendCurrencyCode && ` ${currencyObject.currencyCode}`}
        </Element>
      )}
    </>
  );
}
