import { mapSubscriptionRawResponseToSubscription } from '@/src/lib';
import { SubscriptionService } from '@/src/lib/api/v4/bespoke-client/subcription-service';
import {
  dhulHijjahChallenge,
  fridayGivers,
  futureChallengeDhcHeading,
  futureChallengeFridayGiversHeading,
  futureChallengeRamadanHeading,
  ramadanChallange10Days,
  ramadanChallange30Days,
} from '@/src/lib/constants/sg-constants';
import {
  PlanInfo,
  Subscription,
  SubscriptionObject,
  SubscriptionRawResponse,
} from '@/src/types';

//returns false if typeEnum is amex and donationAmountCurrency is not USD
//returns true otherwise
export function canCreateSubscription(
  typeEnum: string,
  donationAmountCurrency: string,
) {
  // no card was selected or passed in
  if (!typeEnum) {
    return false;
  }
  // Check if the credit card type is 'amex'
  if (typeEnum === 'amex') {
    return donationAmountCurrency === 'USD';
  }

  // Allow subscription for all other cases
  return true;
}

//returns an array of strings corresponding to the future challenges a user is enrolled in
export type GetFutureEnrolledChallengesParams = {
  headings: string[];
  subscriptions: SubscriptionObject[];
};
export async function getFutureEnrolledChallenges(
  userId: number,
): Promise<GetFutureEnrolledChallengesParams> {
  const results: GetFutureEnrolledChallengesParams = {
    headings: [],
    subscriptions: [],
  };
  try {
    const res = await SubscriptionService.getSubscriptions({
      userId,
    });

    const subscriptions = res.map((subscription) =>
      mapSubscriptionRawResponseToSubscription(
        subscription as unknown as SubscriptionRawResponse,
      ),
    );

    results.headings = subscriptions
      .filter(({ unsubscribedAt }) => unsubscribedAt === null)
      .map(({ givingPlanId }) => {
        if (givingPlanId === dhulHijjahChallenge) {
          return futureChallengeDhcHeading;
        } else if (givingPlanId === fridayGivers) {
          return futureChallengeFridayGiversHeading;
        } else if (
          givingPlanId === ramadanChallange30Days ||
          givingPlanId === ramadanChallange10Days
        ) {
          return futureChallengeRamadanHeading;
        }
        return '';
      })
      .filter((category) => category !== '');

    results.subscriptions = subscriptions.filter(
      ({ unsubscribedAt }) => unsubscribedAt === null,
    );

    return results;
  } catch (error) {
    console.error('Error in getFutureEnrolledChallenges', error);
    return results;
  }
}

// Returns 4.0 subscription status based on passed params.
export function isSubscriptionActive(
  subscription: SubscriptionObject,
  plan?: PlanInfo,
): boolean {
  // If giving plan is not passed but .unsubscribedAt has value we will make it as not active. (Examples: Friday Givers)
  if (subscription.unsubscribedAt && !plan) {
    return false;
  }
  // If unsubscribedAt is less than endDate then it should be not active. (Examples: Ramadan, DHC)
  if (
    subscription.unsubscribedAt &&
    plan?.endDate &&
    new Date(subscription.unsubscribedAt).getTime() <
      new Date(plan.endDate).getTime()
  ) {
    return false;
  }
  // Any other case should be considered as active.
  return true;
}

export async function getFridayGiverSubscription(
  userId: number,
  fridayGiverPlanId: number,
): Promise<Subscription> {
  const subscriptions: Subscription[] =
    (await SubscriptionService.getSubscriptions({
      userId,
    })) as unknown as Subscription[];
  if (!subscriptions.length) {
    throw new Error('No Friday Givers subscription exists');
  }
  const [fridayGiverSubsctiption] = subscriptions.filter(
    (s) => s.givingPlanId === fridayGiverPlanId && s.unsubscribedAt === null,
  );
  if (fridayGiverSubsctiption) {
    return fridayGiverSubsctiption;
  } else {
    throw new Error('No Friday Givers subscription exists');
  }
}
