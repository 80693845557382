'use client';

import moment from 'moment';
import { useState, useEffect } from 'react';
import { MiscellaneousService } from '../lib/services/v3/miscellaneous.service';
import { Intervals } from '../types';
import CurrencyFormattedNumber from './CurrencyNumber';

type Props = {
  intervalType: Intervals;
  intervalDuration: number;
  text?: string;
};

export default function AmountRaisedInInterval({
  intervalType = Intervals.HOUR,
  intervalDuration = 1,
  text = `raised in the last ${intervalType.toLowerCase()}`,
}: Props) {
  const [data, setData] = useState<number>(0);

  useEffect(() => {
    const endDate = moment();
    const startDate = moment()
      .subtract(intervalDuration, intervalType)
      .toISOString();

    MiscellaneousService.amountRaisedInInterval(
      startDate,
      endDate.toISOString(),
    ).then((res) => {
      const { success, message, result } = res;

      if (!success) {
        console.error('v3 API error. Message:', message);
        return;
      }

      const { total_funded_during_interval } = result;
      setData(total_funded_during_interval);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {data > 0 && (
        <h1 className="text-4xl font-bold text-black-lg">
          <CurrencyFormattedNumber
            element="span"
            className="text-transparent bg-clip-text bg-gradient-to-r from-[#80D39C] to-[#87CCF3]"
            amount={data}
            currencyCode="USD"
          />{' '}
          {text}
        </h1>
      )}
    </>
  );
}
